import React from "react"
import Image from "gatsby-image"
import { Typography, Box, makeStyles, useMediaQuery } from "@material-ui/core"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import styled from "styled-components"
import { SectionText } from "../SectionText"
import { OutboundLink } from "../OutboundLink"
import { ObfuscatedLink } from "../ObfuscatedLink"
import theme from "../../theme"
import { accent } from "../../theme/colors"
import { useContentfulImage } from "../../hooks/useContentfulImage"

const RichTextParagraph = (node, children) => (
  <SectionText>{children}</SectionText>
)

const RichTextHeading2 = (node, children) => (
  <Typography variant="h2" gutterBottom>
    {children}
  </Typography>
)

const RichTextHeading4 = (node, children) => (
  <Typography variant="h4" gutterBottom>
    {children}
  </Typography>
)

const StyledListItem = styled.li`
  p {
    margin-bottom: ${theme.spacing(1)}px;
  }
`

const RichTextListItem = (node, children) => (
  <StyledListItem>{children}</StyledListItem>
)

const ColorSpan = styled.span`
  color: ${accent};
`

const MAILTO_PREFIX = "mailto:"
const Link = (props) => {
  // Check if it's an email link, if so, obfuscate it
  if (props.uri.startsWith(MAILTO_PREFIX)) {
    const email = props.uri.slice(MAILTO_PREFIX.length)

    return (
      <ObfuscatedLink email={email} obfuscateChildren={false}>
        {props.children}
      </ObfuscatedLink>
    )
  }

  return <OutboundLink href={props.uri}>{props.children}</OutboundLink>
}

const RichTextLink = (node, children) => (
  <ColorSpan>
    <Link uri={node.data.uri}>{children}</Link>
  </ColorSpan>
)

const RichTextBold = (text) => (
  <ColorSpan>
    <strong>{text}</strong>
  </ColorSpan>
)

const StyledBlockQuote = styled.div`
  position: relative;
  padding: 0 ${theme.spacing(3)}px;
  opacity: 0.8;

  ::before {
    content: "";
    position: absolute;
    left: 0;
    height: 100%;
    width: 5px;
    background-color: rgba(0, 0, 0, 0.2);
  }
`

const RichTextBlockQuote = (node, children) => (
  <StyledBlockQuote>{children}</StyledBlockQuote>
)

const useImageStyles = makeStyles({
  root: {
    borderRadius: 16,
  },
  mobile: {
    maxWidth: "100%",
    maxHeight: "75vh",
    margin: `${theme.spacing(4)}px auto`,
  },
  desktop: {
    maxWidth: "75%",
    maxHeight: "50vh",
    margin: `${theme.spacing(8)}px auto`,
  },
})

const RichTextImage = (node) => {
  const id = node.data.target.sys.contentful_id
  const title = node.data.target.fields?.title?.nl
  const fluid = useContentfulImage(id)
  const classes = useImageStyles()
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"))

  return (
    <Image
      className={
        classes.root + " " + (isDesktop ? classes.desktop : classes.mobile)
      }
      fluid={fluid}
      title={title}
      style={{ display: "block" }}
      imgStyle={{ objectFit: "cover" }}
    />
  )
}

const DarkStyles = styled(Box)`
  strong {
    color: white;
  }

  span a {
    color: white;
  }
`

const options = {
  renderNode: {
    [INLINES.HYPERLINK]: RichTextLink,
    [BLOCKS.PARAGRAPH]: RichTextParagraph,
    [BLOCKS.HEADING_2]: RichTextHeading2,
    [BLOCKS.HEADING_4]: RichTextHeading4,
    [BLOCKS.LIST_ITEM]: RichTextListItem,
    [BLOCKS.QUOTE]: RichTextBlockQuote,
    [BLOCKS.EMBEDDED_ASSET]: RichTextImage,
  },
  renderMark: {
    [MARKS.BOLD]: RichTextBold,
  },
  renderText: (text) => {
    return text.split("\n").reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment]
    }, [])
  },
}

export const RichText = (props) => {
  const Container = props.dark ? DarkStyles : Box

  return (
    <Container>{documentToReactComponents(props.children, options)}</Container>
  )
}
