import styled from "styled-components"
import { Button } from "../Button"
import React from "react"

const StyledButton = styled(Button)`
  color: white;
`

export const ActionButton = (props) => (
  <StyledButton
    variant="contained"
    color="primary"
    size="large"
    className={props.className}
    {...props}
  >
    {props.children}
  </StyledButton>
)
