import styled from "styled-components"
import { OutboundLink as GtagLink } from "gatsby-plugin-google-gtag"
import { linkStyles } from "../Link"

export const OutboundLink = styled(GtagLink)`
  ${linkStyles}
`

OutboundLink.defaultProps = {
  ...OutboundLink.defaultProps,
  rel: "noopener",
  target: "_blank",
}
