import styled from "styled-components"
import { accent, dark, background } from "../../theme/colors"
import theme from "../../theme"
import { Box } from "@material-ui/core"

export const slantHeight = 120

function getSlantPolygon({ flipped }) {
  return `polygon(${
    flipped ? "0 0, 100% 100%, 0% 100%" : "100% 0, 0% 100%, 100% 100%"
  })`
}

function getBackgroundColor({ color }) {
  switch (color) {
    case "dark":
      return dark
    case "accent":
      return accent
    case "background":
      return background
    default:
      return "transparent"
  }
}

function getSlantColor({ slant }) {
  return getBackgroundColor({ color: slant })
}

function getColor({ color }) {
  switch (color) {
    case "dark":
      return "white"
    case "accent":
      return "white"
    default:
      return "inherit"
  }
}

function getPadding({
  verticalSpacing = 3,
  horizontalSpacing = 6,
  slant,
  slantCenter,
  noPaddingTop,
}) {
  const verticalThemeSpacing = theme.spacing(verticalSpacing)
  const horizontalThemeSpacing = theme.spacing(horizontalSpacing)
  const paddingBottom = slantCenter
    ? slantHeight / 2
    : verticalThemeSpacing + slantHeight

  return `
    padding: ${verticalThemeSpacing}px ${horizontalThemeSpacing}px;
    ${slant ? `padding-bottom: ${paddingBottom}px;` : ""}
    ${noPaddingTop ? "padding-top: 0;" : ""}
  `
}

function getHeight({ fillScreen }) {
  return fillScreen ? "100vh" : "0"
}

function getContent({ slant }) {
  return slant ? "content: ''" : ""
}

function getMarginTop({ topSlantCenter }) {
  return topSlantCenter ? `-${slantHeight / 2}px` : "0"
}

export const Section = styled(Box)`
  position: relative;
  width: 100%;
  color: ${getColor};
  background-color: ${getBackgroundColor};
  min-height: ${getHeight};
  margin-top: ${getMarginTop};
  z-index: 1;
  ${getPadding} ::after {
    ${getContent};
    background-color: ${getSlantColor};
    position: absolute;
    bottom: -1px;
    left: 0;
    height: ${slantHeight}px;
    width: 100%;
    z-index: -1;
    clip-path: ${getSlantPolygon};
  }
`
