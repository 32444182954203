import React from "react"
import theme from "../../theme"
import colors from "../../theme/colors"
import styled from "styled-components"
import equals from "../../assets/equals-rounded.svg"
import mapShadowsToFilter from "../../util/mapShadowsToFilter"
import { useStaticQuery, graphql } from "gatsby"

const dropShadows = mapShadowsToFilter(theme.shadows)

const Wrapper = styled.div`
  filter: ${dropShadows[10]};
`

const Mask = styled.div`
  position: relative;
  mask-image: url(${equals});
  mask-mode: alpha;
  mask-size: 100%;
  mask-position: center;
  mask-repeat: no-repeat;
  width: 100%;
`

const Spacer = styled.img`
  width: 100%;
`

const Video = styled.video`
  position: absolute;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${colors.accent};

  ::-webkit-media-controls {
    display: none !important;
  }
`

export const LandingVideo = (props) => {
  const data = useStaticQuery(graphql`
    query getIntroVideo {
      allContentfulHomePage {
        edges {
          node {
            logoMovie {
              file {
                url
                contentType
              }
            }
          }
        }
      }
    }
  `)

  const {
    url,
    contentType,
  } = data.allContentfulHomePage.edges[0].node.logoMovie.file

  return (
    <Wrapper>
      <Mask>
        <Spacer src={equals} />
        <Video autoPlay muted loop playsInline>
          <source src={url} type={contentType} />
        </Video>
      </Mask>
    </Wrapper>
  )
}
