import React from "react"
import logo from "../../assets/demos-logo.svg"
import styled from "styled-components"
import theme from "../../theme"

const Wrapper = styled.div`
  flex: 0 0 auto;
  padding: ${theme.spacing(10)}px 0;
`

const Logo = styled.img`
  width: 100%;
`

export const LandingLogo = (props) => (
  <Wrapper>
    <Logo src={logo} />
  </Wrapper>
)
