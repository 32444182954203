import React from "react"
import styled from "styled-components"
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  AppBar,
  Toolbar,
  IconButton,
  Hidden,
  makeStyles,
  Button,
  Grid,
} from "@material-ui/core"
import { Link, OutboundLink } from ".."
import logo from "../../assets/equals-rounded.svg"
import { dark, background } from "../../theme/colors"
import MenuIcon from "@material-ui/icons/Menu"
import theme, { DarkThemeProvider } from "../../theme"
import CloseIcon from "@material-ui/icons/Close"
import { useScroll } from "../../hooks/useScroll"
import InstagramIcon from "@material-ui/icons/Instagram"
import FacebookIcon from "@material-ui/icons/Facebook"
import { graphql, useStaticQuery } from "gatsby"

const useAppBarStyles = makeStyles({
  bar: {
    transition:
      "color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  dark: {
    background: dark,
    color: "white",
  },
  transparent: {
    background: "transparent",
    color: theme.palette.text.primary,
  },
})

const Logo = styled.img`
  width: 10px;
  margin-right: ${theme.spacing(2)}px;
`
const MenuLink = styled(Link)`
  text-decoration: none;
`
const AbsoluteMenuButton = styled(IconButton)`\
  z-index: 10;
  position: fixed;
  right: ${theme.spacing(4)}px;
  top: ${theme.spacing(2)}px;
  background: ${background}
`
const ListFullWidth = styled(List)`
  margin-top: ${theme.spacing(8)}px;
  width: 100vw;
`

const ListItemContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const MenuLinkWrapperMobile = styled.div`
  flex: 0 0 50%;
  margin: ${theme.spacing(1)}px 0;
`

const ListItemMobile = styled(ListItem)`
  flex: 0 0 50%;
  justify-content: center;
`

const ListMenuDesktop = styled(List)`
  display: flex;
  justify-content: center;
`

const DrawerNavigation = styled(Drawer)`
  width: 100%;
`

const ToolbarCenter = styled(Toolbar)`
  justify-content: center;
`

const introModeQuery = graphql`
  query getIntroMode {
    allContentfulHomePage {
      edges {
        node {
          introMode
          mainShowIntroprogramma
        }
      }
    }
  }
`

export function MenuDrawer({ startTransparent = false }) {
  const [menuOpen, setMenuOpen] = React.useState(false)
  const scrollAtTop = useScroll()
  const appBarClasses = useAppBarStyles()
  const data = useStaticQuery(introModeQuery)
  const {
    introMode,
    mainShowIntroprogramma,
  } = data.allContentfulHomePage.edges[0].node

  const menuItems = [
    { to: "/", label: "Home" },
    ...(introMode ? [{ to: "/inschrijven", label: "Inschrijven" }] : []),
    ...(mainShowIntroprogramma
      ? [{ to: "/introprogramma", label: "Introprogramma" }]
      : []),
    { to: "/kernwaarden", label: "Over Demos" },
    { to: "/groeperingen", label: "Groeperingen" },
    { to: "/ledenervaringen", label: "Ledenervaringen" },
    { to: "/zaalverhuur", label: "Zaalverhuur" },
    { to: "/faq", label: "FAQ" },
    { to: "/zakelijk", label: "Zakelijk" },
  ]

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setMenuOpen(open)
  }

  return (
    <React.Fragment>
      {/* Mobile Navigation */}
      <Hidden lgUp>
        <AbsoluteMenuButton
          edge="start"
          color="primary"
          onClick={toggleDrawer(true)}
          aria-label="menu"
        >
          <MenuIcon />
        </AbsoluteMenuButton>
        <DrawerNavigation
          anchor={"right"}
          open={menuOpen}
          onClose={toggleDrawer(false)}
        >
          <ListFullWidth>
            <AbsoluteMenuButton onClick={toggleDrawer(false)}>
              <CloseIcon color="primary" />
            </AbsoluteMenuButton>
            <ListItemContainerMobile>
              {menuItems.map((menuItem) => (
                <MenuLinkWrapperMobile key={menuItem.label}>
                  <MenuLink to={menuItem.to}>
                    <ListItemMobile button>
                      <Logo src={logo} />
                      <ListItemText primary={menuItem.label} />
                    </ListItemMobile>
                  </MenuLink>
                </MenuLinkWrapperMobile>
              ))}
              <Grid xs={12}>
                <Grid xs={6}>
                  <OutboundLink href="https://www.facebook.com/ESVDemos684/">
                    <Box mx={2}>
                      <Button size="large">
                        <FacebookIcon></FacebookIcon>
                      </Button>
                    </Box>
                  </OutboundLink>
                </Grid>
                <Grid xs={6}>
                  <OutboundLink href="https://www.instagram.com/esvdemos/">
                    <Box mx={2}>
                      <Button size="large">
                        <InstagramIcon></InstagramIcon>
                      </Button>
                    </Box>
                  </OutboundLink>
                </Grid>
              </Grid>
            </ListItemContainerMobile>
          </ListFullWidth>
        </DrawerNavigation>
      </Hidden>

      {/* Desktop Navigation */}
      <Hidden mdDown>
        <DarkThemeProvider dark={!startTransparent || !scrollAtTop}>
          <AppBar
            position="sticky"
            className={
              appBarClasses.bar +
              " " +
              (startTransparent && scrollAtTop
                ? appBarClasses.transparent
                : appBarClasses.dark)
            }
            elevation={scrollAtTop ? 0 : 10}
          >
            <ToolbarCenter>
              <ListMenuDesktop>
                {menuItems.map((menuItem) => (
                  <MenuLink to={menuItem.to} key={menuItem.label}>
                    <Box mx={2}>
                      <Button size="large">{menuItem.label}</Button>
                    </Box>
                  </MenuLink>
                ))}

                <OutboundLink href="https://www.facebook.com/ESVDemos684/">
                  <Box mx={2}>
                    <Button size="large">
                      <FacebookIcon></FacebookIcon>
                    </Button>
                  </Box>
                </OutboundLink>

                <OutboundLink href="https://www.instagram.com/esvdemos/">
                  <Box mx={2}>
                    <Button size="large">
                      <InstagramIcon></InstagramIcon>
                    </Button>
                  </Box>
                </OutboundLink>
              </ListMenuDesktop>
            </ToolbarCenter>
          </AppBar>
        </DarkThemeProvider>
      </Hidden>
    </React.Fragment>
  )
}
