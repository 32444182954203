import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Image from "gatsby-image"
import styled from "styled-components"
import { Slider } from "../Slider"
import { OutboundLink } from "../../components"
import theme from "../../theme"
import { cardBoxShadow, cardBorderRadius } from "../../styles/card"

const sponsorImagesQuery = graphql`
  query getSponsorImages {
    allContentfulSponsor(filter: { private: { eq: false } }) {
      nodes {
        name
        url
        zakelijkePagina
        private
        sponsorPage {
          slug
        }
        image {
          id
          title
          fixed(height: 140) {
            ...GatsbyContentfulFixed_noBase64
          }
        }
      }
    }

    allContentfulSponsorCollection {
      nodes {
        name {
          name
        }
        sponsors {
          name
          url
          image {
            id
            title
            fixed(
              width: 250
              height: 140
              resizingBehavior: PAD
              background: "white"
            ) {
              ...GatsbyContentfulFixed_noBase64
            }
          }
        }
      }
    }

    contentfulZakelijkPage {
      sponsorsCollections {
        name {
          name
        }
        sponsors {
          name
          url
          image {
            id
            title
            fixed(
              width: 250
              height: 140
              resizingBehavior: PAD
              background: "white"
            ) {
              ...GatsbyContentfulFixed_noBase64
            }
          }
        }
      }
    }
  }
`

const SliderImageWrapper = styled.div`
  padding: ${theme.spacing(1)}px ${theme.spacing(3)}px;
  margin: ${theme.spacing(1.5)}px;
  background-color: white;
  overflow: visible;
  ${cardBoxShadow};
  ${cardBorderRadius};
`

const CollectionSliderWrapper = styled(SliderImageWrapper)`
  width: ${250 + 2 * theme.spacing(3)}px;
  height: ${140 + 2 * theme.spacing(1)}px;
`

const fadeSliderOptions = {
  fade: true,
  draggable: false,
}

const SponsorLinkComponent = ({ sponsor, children }) => {
  // If the sponsor has a sponsor page, return an internal link
  if (sponsor?.sponsorPage?.slug) {
    const url = `sponsor/${sponsor.sponsorPage.slug}`
    return <Link to={url}>{children}</Link>
  }

  return <OutboundLink href={sponsor.url}>{children}</OutboundLink>
}

const getSponsorImage = (sponsor) => (
  <SponsorLinkComponent sponsor={sponsor} key={sponsor.name}>
    <Image
      fixed={sponsor.image.fixed}
      alt={sponsor.image.title}
      backgroundColor="white"
    />
  </SponsorLinkComponent>
)

const getSponsorCollectionSlider = (sponsors) => {
  const images = sponsors.map(getSponsorImage)
  return (
    <Slider
      random
      elements={images}
      flickityOptions={fadeSliderOptions}
    ></Slider>
  )
}

const getSponsorElement = (sponsor) => (
  <SliderImageWrapper key={sponsor.name}>
    {getSponsorImage(sponsor)}
  </SliderImageWrapper>
)

const getSponsorCollectionElement = ({ name, sponsors }) => (
  <CollectionSliderWrapper key={name.name}>
    {getSponsorCollectionSlider(sponsors)}
  </CollectionSliderWrapper>
)

export const SponsorSlider = (props) => {
  const data = useStaticQuery(sponsorImagesQuery)
  const sponsors = props.zakelijk
    ? data.allContentfulSponsor.nodes.filter(
      (sponsor) => sponsor.zakelijkePagina == true && sponsor.private == false
    )
    : data.allContentfulSponsor.nodes.filter(
      (sponsor) => sponsor.zakelijkePagina == false && sponsor.private == false
    )
  const sponsorCollections = props.zakelijk
    ? data.contentfulZakelijkPage.sponsorsCollections
    : data.allContentfulSponsorCollection.nodes

  const sponsorElements = sponsors.map(getSponsorElement)
  const sponsorcollectionElements = sponsorCollections.map(
    getSponsorCollectionElement
  )

  const elements = [...sponsorElements, ...sponsorcollectionElements]

  return <Slider random elements={elements} />
}
