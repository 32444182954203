import React from "react"
import Loadable from "react-loadable"
import { random } from "lodash"

const defaultFlickityOptions = {
  prevNextButtons: false,
  pageDots: false,
  wrapAround: true,
  autoPlay: true,
  selectedAttraction: 0.17,
  friction: 0.8,
}

const ReactFlickityComponent = import("../../vendor/flickity")

export const Slider = (props) => {
  const Flickity = Loadable({
    loader: () => ReactFlickityComponent,
    loading() {
      return <div>{props.elements}</div>
    },
  })

  const flickityOptions = {
    ...defaultFlickityOptions,
    ...props.flickityOptions,

    // Lodash' random method upper bound is included in the results so we need the maximum index it can be
    initialIndex: props.random ? random(props.elements.length - 1) : 0,
  }

  return (
    <Flickity static options={flickityOptions}>
      {props.elements}
    </Flickity>
  )
}
