import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"

function generateTitle(title, subtitle) {
  const maxTitleLength = 50
  const infix = " - "

  if (!subtitle.long && !subtitle.short) {
    return title.long
  }

  let result = title.short + infix + subtitle.long
  if (result.length < maxTitleLength) {
    return result
  }

  return title.short + infix + subtitle.short
}

const metaDataQuery = graphql`
  query getMetaData {
    site {
      siteMetadata {
        siteTitle {
          long
          short
        }
      }
    }
  }
`

export const SiteTitle = (props) => {
  const {
    site: {
      siteMetadata: { siteTitle },
    },
  } = useStaticQuery(metaDataQuery)

  const subtitle = {
    long: props.long || props.title,
    short: props.short || props.title,
  }

  return <Helmet title={generateTitle(siteTitle, subtitle)} />
}
