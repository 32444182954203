import { Link as GatsbyLink } from "gatsby"
import styled from "styled-components"

export const linkStyles = `
  color: inherit;
`

export const Link = styled(GatsbyLink)`
  ${linkStyles}
`
