import React from "react"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import styled from "styled-components"
import { Link } from "gatsby"
import theme from "../../theme"

const StyledIcon = styled(ArrowBackIcon)`
  position: absolute;
  left: ${theme.spacing(3)}px;
  top: ${theme.spacing(3)}px;
`

export const BackButton = (props) => (
  <Link {...props}>
    <StyledIcon {...props} />
  </Link>
)
