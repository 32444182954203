import styled from "styled-components"
import { cardBoxShadow, cardBorderRadius } from "../../styles/card"
import theme from "../../theme"

export const Card = styled.div`
  padding: ${theme.spacing(3)}px;
  background-color: white;
  color: black;
  ${cardBoxShadow};
  ${cardBorderRadius};
`
