import { parse, stringify } from "css-box-shadow"
import Color from "color"

function changeShadowElementColor(shadowElement, color) {
  const shadowColor = Color(shadowElement.color)
  const themeColor = Color(color)
  const newColor = themeColor.alpha(shadowColor.alpha())

  const newShadowElement = {
    ...shadowElement,
    color: newColor.rgb(),
  }

  return newShadowElement
}

export default function changeShadowColor(shadow, color) {
  const shadowElements = parse(shadow)

  return stringify(
    shadowElements.map((shadowElement) =>
      changeShadowElementColor(shadowElement, color)
    )
  )
}
