import MaterialButton from "@material-ui/core/Button"
import styled from "styled-components"
import theme from "../../theme"
import changeShadowColor from "../../util/changeShadowColor"

function getShadowForColor(themeColor, depth = 2) {
  const shadow = theme.shadows[depth]
  const color = theme.palette[themeColor].main
  return changeShadowColor(shadow, color)
}

export const Button = styled(MaterialButton)`
  letter-spacing: 2px;
  box-shadow: ${(props) => getShadowForColor(props.color)};

  :hover {
    box-shadow: ${(props) => getShadowForColor(props.color, 8)};
  }
`
