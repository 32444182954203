import React from "react"
import styled from "styled-components"
import { Typography, Box } from "@material-ui/core"

const CopyrightText = styled(Typography)`
  margin-bottom: 0;
  text-align: center;
  opacity: 0.5;
`

export const Copyright = (props) => (
  <Box mt={props.mt}>
    <CopyrightText variant="body2">
      Copyright {new Date().getFullYear()} @ E.S.V. Demos
    </CopyrightText>
  </Box>
)
