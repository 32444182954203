import { Typography } from "@material-ui/core"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"
import theme from "../../theme"
import { ChevronRight } from "@material-ui/icons"
import React from "react"
import { cardBoxShadow, cardBorderRadius } from "../../styles/card"

const Wrapper = styled.div`
  display: block;
  position: relative;
  background: white;
  color: black;
  padding: ${theme.spacing(2)}px;
  margin: 0 auto;
  margin-top: ${theme.spacing(4)}px;
  max-width: 400px;
  ${cardBoxShadow};
  ${cardBorderRadius};
`

const Image = styled.img``

export const ImageButton = (props) => {
  return (
    <Wrapper>
      <Grid alignItems="center" spacing={2} container>
        <Grid item xs={3}>
          <Image src={props.imageUrl} />
        </Grid>
        <Grid item xs={props.chevron ? 7 : 9}>
          <Typography variant="h5" align="left">
            {props.title}
          </Typography>
          <Typography variant="body2" align="left">
            {props.subtitle}
          </Typography>
        </Grid>
        {props.chevron && (
          <Grid item xs={2}>
            <ChevronRight fontSize="large" />
          </Grid>
        )}
      </Grid>
    </Wrapper>
  )
}
