import React from "react"
import Image from "gatsby-image"
import { parseISO, format } from "date-fns"
import dutch from "date-fns/locale/nl"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { Typography } from "@material-ui/core"
import { capitalize } from "lodash"
import theme from "../../theme"
import { Button } from "../Button"
import { cardBoxShadow, cardBorderRadius } from "../../styles/card"
import changeShadowColor from "../../util/changeShadowColor"
import { accent, accentDark } from "../../theme/colors"

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

const Header = styled.div`
  position: relative;
  width: calc(100% - ${theme.spacing(3)}px * 2);
  margin-left: ${theme.spacing(3)}px;
  margin-top: -${theme.spacing(3)}px;
  padding: ${theme.spacing(2)}px;
  transform: translateY(50%);
  z-index: 1;
  color: white;
  text-align: center;
  background: linear-gradient(90deg, ${accent} 0%, ${accentDark} 100%);
  ${changeShadowColor(cardBoxShadow, accent)};
  ${cardBorderRadius};
`

const Container = styled.div``

const EventImage = styled(Image)`
  width: 100%;
  display: block;
  ${cardBoxShadow};
  ${cardBorderRadius};
`

const StyledButton = styled(Button)`
  position: absolute;
  bottom: ${theme.spacing(4)}px;
  left: 50%;
  transform: translateX(-50%);
`

const query = graphql`
  query getEventsImage {
    allContentfulHomePage {
      edges {
        node {
          eventsOverride {
            eventTitle
            eventDate
            eventButtonLabel
            eventButtonUrl
            eventImage {
              fluid {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
  }
`

const landingEventElement = (event) => {
  return (
    <Wrapper>
      <Header>
        <Typography variant="h5">{event.eventTitle}</Typography>
        <Typography variant="subtitle1">
          {formattedDate(event.eventDate)}
        </Typography>
      </Header>
      <Container>
        <EventImage fluid={event.eventImage.fluid} />
      </Container>
      {event.eventButtonLabel && event.eventButtonUrl ? (
        <StyledButton
          variant="contained"
          color="secondary"
          href={event.eventButtonUrl}
        >
          {event.eventButtonLabel}
        </StyledButton>
      ) : (
        ""
      )}
    </Wrapper>
  )
}

const formattedDate = (date) =>
  capitalize(
    format(parseISO(date), "EEEE d MMMM yyyy - HH:mm", {
      locale: dutch,
    })
  )

export const LandingEvent = (props) => {
  const data = useStaticQuery(query)
  const events = data.allContentfulHomePage.edges[0].node.eventsOverride
  const sortedEvents = events.sort(function (a, b) {
    return new Date(a.eventDate) - new Date(b.eventDate)
  })
  const eventsList = sortedEvents.map(landingEventElement)

  return <div>{eventsList}</div>
}
