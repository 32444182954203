import { parse, stringify } from "css-box-shadow"

function shadowElementToDropShadow(shadowElement) {
  delete shadowElement.spreadRadius
  return `drop-shadow(${stringify([shadowElement])})`
}

export default function mapShadowsToFilter(shadows) {
  return shadows
    .map((shadow) => parse(shadow))
    .map((shadowElements) =>
      shadowElements.map(shadowElementToDropShadow).join(" ")
    )

  /* return dropShadows.map(dropShadow =>
    stringify(
      parse(dropShadow).map(dropShadowElement => {
        delete dropShadowElement.spreadRadius
        return dropShadowElement
      })
    )
  ) */
}
