import { graphql, useStaticQuery } from "gatsby"

export const useContentfulImage = (id) => {
  const { allContentfulAsset } = useStaticQuery(
    graphql`
      query CONTENTFUL_IMAGE_QUERY {
        allContentfulAsset(
          filter: { file: { contentType: { in: ["image/png", "image/jpeg"] } } }
        ) {
          nodes {
            contentful_id
            fluid(quality: 85) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  )

  return allContentfulAsset.nodes.find((asset) => asset.contentful_id === id)
    .fluid
}
